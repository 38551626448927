@use "../variables/typography";
@use "../variables/borders";
@use "../mixins/breakpoints";

@import "../mixins/skeleton";

:host(:not(:defined)) {
    visibility: hidden;
}

:host(
    :not(
        atlas-text,
        atlas-heading,
        atlas-caption,
        atlas-display,
        atlas-page-content,
        atlas-page-tabs-wrapper,
        atlas-page-tab,
        atlas-layout
    )
) {
    font-family: typography.$font-family;
    font-size: typography.$body-md-font-size;
    font-weight: typography.$body-font-weight;
    line-height: typography.$body-line-height;
}

:host([skeleton-loading]) {
    display: contents;
    pointer-events: none;
}

:host([hide-on-desktop]) {
    @include breakpoints.breakpoint-up-lg {
        display: none !important;
    }
}

:host([hide-on-mobile]) {
    @include breakpoints.breakpoint-down-lg {
        display: none !important;
    }
}

.skeleton {
    @include skeleton;
}

.skeleton-circle {
    @include skeleton;

    border-radius: borders.$border-radius-circle;
}

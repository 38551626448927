@use "sass:map";
@use "colors";

/* Shadows */
$atlas-primary-shadow: 0 0 0 0.25rem rgba(colors.$atlas-primary-rgb, 40%);
$atlas-focus-shadow-color: rgba(colors.$atlas-primary-300-rgb, 0.25);
$atlas-focus-shadow: 0 0 0 0.25rem $atlas-focus-shadow-color;

/* Elevations */
$elevation-1: 0 1px 4px rgba(0, 0, 0, 25%);

@mixin make-focus-shadow($theme) {
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba(map.get(colors.$atlas-variant-colors-rgb-map, $theme, "500"), 0.25);
}

@font-face {
    font-family: "Atlas-Icons";
    src: url("/codebuild/output/src521354341/src/packages/webcomponents/src/assets/fonts/atlas-icons/fonts/Atlas-Icons.eot?vb5ckv");
    src:
        url("/codebuild/output/src521354341/src/packages/webcomponents/src/assets/fonts/atlas-icons/fonts/Atlas-Icons.eot?vb5ckv#iefix") format("embedded-opentype"),
        url("/codebuild/output/src521354341/src/packages/webcomponents/src/assets/fonts/atlas-icons/fonts/Atlas-Icons.ttf?vb5ckv") format("truetype"),
        url("/codebuild/output/src521354341/src/packages/webcomponents/src/assets/fonts/atlas-icons/fonts/Atlas-Icons.woff?vb5ckv") format("woff"),
        url("/codebuild/output/src521354341/src/packages/webcomponents/src/assets/fonts/atlas-icons/fonts/Atlas-Icons.svg?vb5ckv#Atlas-Icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.atlas-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "Atlas-Icons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ati-address-book:before {
    content: "\e905";
}
.ati-alert-circle:before {
    content: "\e902";
}
.ati-alert-triangle:before {
    content: "\e904";
}
.ati-align-justify:before {
    content: "\e906";
}
.ati-apps:before {
    content: "\e907";
}
.ati-arrow-down:before {
    content: "\e90c";
}
.ati-arrow-down-circle:before {
    content: "\e90d";
}
.ati-arrow-left:before {
    content: "\e910";
}
.ati-arrow-right:before {
    content: "\e912";
}
.ati-arrow-right-left:before {
    content: "\e908";
}
.ati-arrow-up:before {
    content: "\e914";
}
.ati-arrow-up-circle:before {
    content: "\e93f";
}
.ati-arrow-up-right:before {
    content: "\e909";
}
.ati-asaas-logo:before {
    content: "\e90a";
}
.ati-bank:before {
    content: "\e932";
}
.ati-bar-chart:before {
    content: "\e91b";
}
.ati-barcode:before {
    content: "\e921";
}
.ati-bars:before {
    content: "\e998";
}
.ati-base-logo:before {
    content: "\e93e";
}
.ati-bell:before {
    content: "\e91e";
}
.ati-box:before {
    content: "\e923";
}
.ati-briefcase:before {
    content: "\e926";
}
.ati-calendar:before {
    content: "\e93b";
}
.ati-camera:before {
    content: "\e90b";
}
.ati-card:before {
    content: "\e951";
}
.ati-card-mastercard:before {
    content: "\e953";
}
.ati-celebrate:before {
    content: "\e948";
}
.ati-check:before {
    content: "\e92b";
}
.ati-check-circle:before {
    content: "\e92c";
}
.ati-check-filled:before {
    content: "\e95d";
}
.ati-chevron-down:before {
    content: "\e92e";
}
.ati-chevron-left:before {
    content: "\e92f";
}
.ati-chevron-right:before {
    content: "\e930";
}
.ati-chevron-up:before {
    content: "\e931";
}
.ati-circle:before {
    content: "\e937";
}
.ati-clock:before {
    content: "\e939";
}
.ati-clock-filled:before {
    content: "\e95e";
}
.ati-cog:before {
    content: "\e9cf";
}
.ati-concentric-circles:before {
    content: "\e93a";
}
.ati-contactless:before {
    content: "\e959";
}
.ati-copy:before {
    content: "\e947";
}
.ati-corner-up-right:before {
    content: "\e94f";
}
.ati-dashboard:before {
    content: "\e90e";
}
.ati-dollar-sign:before {
    content: "\e957";
}
.ati-dollar-zap:before {
    content: "\e903";
}
.ati-download:before {
    content: "\e958";
}
.ati-envelope:before {
    content: "\e992";
}
.ati-eye:before {
    content: "\e95f";
}
.ati-eye-filled:before {
    content: "\e966";
}
.ati-eye-off:before {
    content: "\e960";
}
.ati-facebook:before {
    content: "\e943";
}
.ati-file:before {
    content: "\e965";
}
.ati-file-check:before {
    content: "\e96c";
}
.ati-file-dollar:before {
    content: "\e901";
}
.ati-file-text:before {
    content: "\e968";
}
.ati-file-users:before {
    content: "\e96b";
}
.ati-files:before {
    content: "\e90f";
}
.ati-filter:before {
    content: "\e96a";
}
.ati-gift:before {
    content: "\e92d";
}
.ati-github:before {
    content: "\e94a";
}
.ati-globe:before {
    content: "\e94d";
}
.ati-hand-card:before {
    content: "\e952";
}
.ati-hand-holding-money:before {
    content: "\e946";
}
.ati-hand-shake:before {
    content: "\e911";
}
.ati-help-circle:before {
    content: "\e97e";
}
.ati-hierarchy:before {
    content: "\e945";
}
.ati-home:before {
    content: "\e938";
}
.ati-id-card:before {
    content: "\e933";
}
.ati-info:before {
    content: "\e983";
}
.ati-instagram:before {
    content: "\e984";
}
.ati-invoice:before {
    content: "\e900";
}
.ati-key:before {
    content: "\e913";
}
.ati-keyboard:before {
    content: "\ea1a";
}
.ati-link:before {
    content: "\e98a";
}
.ati-linkedin:before {
    content: "\e944";
}
.ati-list:before {
    content: "\e98d";
}
.ati-loader:before {
    content: "\e934";
}
.ati-lock:before {
    content: "\e98f";
}
.ati-lock-filled:before {
    content: "\e963";
}
.ati-log-in:before {
    content: "\e990";
}
.ati-magnifier:before {
    content: "\e9cc";
}
.ati-mail-box:before {
    content: "\e915";
}
.ati-map-pin:before {
    content: "\e954";
}
.ati-maximize:before {
    content: "\e967";
}
.ati-megaphone:before {
    content: "\e936";
}
.ati-message-circle:before {
    content: "\e999";
}
.ati-minus:before {
    content: "\e99f";
}
.ati-minus-circle:before {
    content: "\e9a0";
}
.ati-money:before {
    content: "\e916";
}
.ati-money-filled:before {
    content: "\e961";
}
.ati-money-notes:before {
    content: "\e949";
}
.ati-monitor:before {
    content: "\e9a2";
}
.ati-monitor-chart-up:before {
    content: "\e924";
}
.ati-moon:before {
    content: "\e94e";
}
.ati-open-finance:before {
    content: "\e91c";
}
.ati-paperclip:before {
    content: "\e9ad";
}
.ati-pencil:before {
    content: "\e95c";
}
.ati-phone:before {
    content: "\e9b2";
}
.ati-pix:before {
    content: "\e922";
}
.ati-plus:before {
    content: "\e9bc";
}
.ati-plus-circle:before {
    content: "\e9bd";
}
.ati-power:before {
    content: "\e9c0";
}
.ati-printer:before {
    content: "\e9c1";
}
.ati-refresh:before {
    content: "\e9c3";
}
.ati-refresh-dollar:before {
    content: "\e917";
}
.ati-repeat:before {
    content: "\e929";
}
.ati-rotate:before {
    content: "\e9c8";
}
.ati-rotate-dollar:before {
    content: "\e918";
}
.ati-send:before {
    content: "\e9cd";
}
.ati-serasa:before {
    content: "\e919";
}
.ati-share:before {
    content: "\e941";
}
.ati-shield:before {
    content: "\e91a";
}
.ati-shopping-bag:before {
    content: "\e95a";
}
.ati-shopping-kart-down:before {
    content: "\e925";
}
.ati-shopping-kart-right:before {
    content: "\e969";
}
.ati-slash:before {
    content: "\e9db";
}
.ati-sliders:before {
    content: "\e9dc";
}
.ati-smartphone:before {
    content: "\e9dd";
}
.ati-sort:before {
    content: "\e935";
}
.ati-spanner:before {
    content: "\e927";
}
.ati-split-arrows:before {
    content: "\e93d";
}
.ati-square:before {
    content: "\e9e0";
}
.ati-square-signs:before {
    content: "\e91d";
}
.ati-star:before {
    content: "\e940";
}
.ati-star-filled:before {
    content: "\e964";
}
.ati-sun:before {
    content: "\e950";
}
.ati-tag:before {
    content: "\e9e7";
}
.ati-three-dots:before {
    content: "\e9a5";
}
.ati-three-dots-horinzontal:before {
    content: "\e93c";
}
.ati-thumbs-down:before {
    content: "\e94c";
}
.ati-thumbs-up:before {
    content: "\e94b";
}
.ati-ticket:before {
    content: "\e956";
}
.ati-trash:before {
    content: "\e9f1";
}
.ati-truck:before {
    content: "\e9f6";
}
.ati-unlock:before {
    content: "\e955";
}
.ati-upload:before {
    content: "\e9fe";
}
.ati-user:before {
    content: "\ea00";
}
.ati-user-filled:before {
    content: "\e95b";
}
.ati-user-plus:before {
    content: "\ea03";
}
.ati-users:before {
    content: "\ea05";
}
.ati-video:before {
    content: "\e92a";
}
.ati-virtual-card:before {
    content: "\e91f";
}
.ati-wallet:before {
    content: "\e920";
}
.ati-whatsapp:before {
    content: "\e928";
}
.ati-x:before {
    content: "\ea11";
}
.ati-x-filled:before {
    content: "\e962";
}
.ati-youtube:before {
    content: "\e942";
}

@use "../variables/z-index";

.atlas-loading-backdrop {
    position: fixed;
    inset: 0;
    background-color: rgba(255, 255, 255, 90%);
    z-index: z-index.$zindex-loading-backdrop;

    &.inside-element {
        position: absolute;
    }
}

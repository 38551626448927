/* Import das fontes */
@import "../assets/fonts/atlas-icons/atlas-icons";
@import "../assets/fonts/open-sans/open-sans";

/* Componentes que necessitam de estilos globais */
@import "reboot";
@import "globals/utils";
@import "globals/root";
@import "components/alert";
@import "components/backdrop";
@import "components/froala";
@import "components/loading";
@import "components/uploader";

@use "variables/typography";
@use "variables/colors";

*[hidden] {
    display: none !important;
}

.hide {
    display: none;
}

html {
    margin: 0;
}

body {
    margin: 0;
}

code {
    font-size: typography.$body-xs-font-size;
    color: colors.$pink;
    word-wrap: break-word;

    a > & {
        color: inherit;
    }
}

.disable-scroll,
.disable-scroll-dropdown,
.disable-scroll-modal,
.disable-scroll-offcanvas,
.disable-scroll-hotspot {
    overflow: hidden;

    atlas-screen {
        overflow: hidden;
    }
}

ul.atlas-simple-list {
    color: colors.$text-muted;

    &.small {
        margin: 0;
        padding-left: 1rem;

        li {
            font-size: typography.$body-xs-font-size;
            line-height: typography.$body-line-height;
        }
    }
}

@use "../variables/colors";
@use "../variables/borders";
@use "../variables/transitions";
@use "../variables/z-index";

.atlas-uploader-expanded-drop {
    position: relative;

    .atlas-droppable-area {
        width: calc(100% - 2rem);
        height: calc(100% - 2rem);
        top: 1rem;
        left: 1rem;
        pointer-events: none;
        position: absolute;
        z-index: z-index.$zindex-uploader;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: borders.$border-radius;
        padding: 4rem;
        box-sizing: border-box;
        border: 1px solid colors.$atlas-primary;
        background-color: rgba(colors.$white, 90%);
        opacity: 0;
        transition: opacity transitions.$transition-smooth-medium;
    }

    &:is(atlas-wizard) {
        .atlas-droppable-area {
            position: fixed !important;
            height: calc(100vh - 3rem - 144px) !important;
            top: 50% !important;
            transform: translateY(-50%);
        }
    }

    &.dz-drag-hover {
        .atlas-droppable-area {
            opacity: 1;
        }
    }
}

@use "sass:map";
@use "../mixins/skeleton";
@use "../variables/borders";
@use "../variables/colors";
@use "../variables/shadows";
@use "../variables/typography";

@import "../components/base-style";
@import "froala-editor/css/froala_editor.pkgd";
@import "tributejs/tribute.css";

$theme-by-status: (
    "info": "secondary",
    "success": "success",
    "warning": "warning",
    "error": "danger"
);

atlas-editor {
    width: 100%;
    display: block;
}

.tribute-container {
    ul {
        background: colors.$white;
        border: borders.$border-thin-default;
        border-radius: borders.$border-radius;
        margin: 0;
        padding: 0.5rem 0;

        li {
            padding: 0.5rem 1rem;
            font-family: typography.$font-family;
            font-size: typography.$body-sm-font-size;
            font-weight: typography.$body-font-weight;
            line-height: typography.$body-line-height;
            color: colors.$text-body;
            cursor: pointer;

            &:hover,
            &.highlight {
                background-color: colors.$atlas-primary-100;
            }
        }

        .not-found {
            padding: 0.5rem 1rem;
            font-family: typography.$font-family;
            font-size: typography.$body-sm-font-size;
            font-weight: typography.$body-font-weight;
            line-height: typography.$body-line-height;
            color: colors.$text-muted;
            display: block;
        }
    }
}

.fr-toolbar.fr-top {
    border-radius: borders.$border-radius-sm borders.$border-radius-sm 0 0;
    border-color: colors.$gray-border;
}

.fr-box.fr-basic,
.fr-box.fr-inline {
    border-radius: borders.$border-radius-sm;

    .fr-wrapper {
        border-color: colors.$gray-border;
        border-radius: 0 0 borders.$border-radius-sm borders.$border-radius-sm;

        .fr-element {
            padding: 1rem;
            font-family: typography.$font-family;
            font-size: typography.$body-md-font-size;
            font-weight: typography.$body-font-weight;
            line-height: typography.$body-line-height;
            color: colors.$gray-900;
            cursor: text;
            user-select: auto;

            p {
                margin: 0.875rem 0;
            }

            p:first-child {
                margin-top: 0;
            }
        }

        .fr-placeholder {
            font-family: typography.$font-family;
            font-size: typography.$body-md-font-size;
            font-weight: typography.$body-font-weight;
            line-height: typography.$body-line-height;
            color: colors.$text-muted;
        }
    }
}

.fr-second-toolbar {
    display: none;
}

.atlas-editor.resizable {
    .fr-wrapper {
        resize: vertical;
    }
}

.atlas-editor.atlas-editor-inline {
    .fr-wrapper {
        border: borders.$border-thin-default;
        border-radius: borders.$border-radius-sm borders.$border-radius-sm;
        padding: 0.5rem 0.75rem;

        .fr-element {
            white-space: nowrap;
            overflow: hidden;
            padding: 0;

            p:first-child {
                margin: 0;
            }
        }
    }

    /* stylelint-disable-next-line */
    .fr-placeholder {
        top: 0.5rem;
        left: 0.75rem;
    }
}

.fr-toolbar.fr-inline.fr-above {
    transform: translateY(-20px);
}

.atlas-editor.atlas-editor-disabled {
    .fr-toolbar.fr-top {
        display: none;
    }

    .fr-wrapper {
        border-top: borders.$border-thin-default !important;
        border-radius: borders.$border-radius-sm borders.$border-radius-sm;
        background-color: colors.$gray-100;
    }
}

.atlas-editor.atlas-editor-skeleton {
    @include skeleton.skeleton;

    pointer-events: none !important;

    .fr-wrapper {
        border: none !important;
        background: transparent;

        .fr-element {
            color: transparent;
        }
    }

    .fr-toolbar {
        display: contents;
        border: none;
    }

    .fr-command.fr-btn {
        @include skeleton.skeleton;

        width: fit-content;
        margin: 4px 2px;

        svg {
            visibility: hidden;

            path {
                transition: none;
            }
        }
    }
}

.atlas-editor:focus-within {
    box-shadow: shadows.$atlas-focus-shadow;

    .fr-toolbar,
    .fr-wrapper {
        border-color: colors.$atlas-primary-300 !important;
    }
}

.status-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: typography.$body-xs-font-size;
    font-family: typography.$font-family;
}

atlas-editor[skeleton-loading] {
    .status-feedback {
        @include skeleton.skeleton;

        color: transparent !important;
        margin-top: 0.25rem;
    }
}

@each $status, $theme in $theme-by-status {
    $status-color: map.get(colors.$atlas-theme-colors, $theme);
    $status-rgb-color: map.get(colors.$atlas-rgb-colors-map, $theme);

    @if $status != "info" {
        .atlas-editor.is-#{$status} {
            .fr-toolbar.fr-top,
            .fr-wrapper {
                border-color: $status-color !important;
            }

            ~ .status-feedback {
                display: block;
            }

            ~ .status-#{$status} {
                color: $status-color;
            }

            &:focus-within {
                @include shadows.make-focus-shadow($theme);

                .fr-toolbar,
                .fr-wrapper {
                    border-color: $status-color;
                }
            }
        }
    }
}

@use "../variables/spacing";
@use "../../styles/mixins/breakpoints";

$directions: (
    t: top,
    b: bottom,
    l: left,
    r: right
);

.atlas-align-to-input {
    margin-top: 2rem !important;
}

@each $number, $space in spacing.$spacers {
    .atlas-gap-#{$number} {
        gap: $space !important;
    }

    @each $directionName, $direction in $directions {
        .atlas-m#{$directionName}-#{$number} {
            margin-#{$direction}: $space !important;
        }

        .atlas-p#{$directionName}-#{$number} {
            margin-#{$direction}: $space !important;
        }
    }
}

@include breakpoints.breakpoint-down-lg {
    @each $number, $space in spacing.$spacers {
        .mobile-atlas-gap-#{$number} {
            gap: $space !important;
        }

        @each $directionName, $direction in $directions {
            .mobile-atlas-m#{$directionName}-#{$number} {
                margin-#{$direction}: $space !important;
            }

            .mobile-atlas-p#{$directionName}-#{$number} {
                margin-#{$direction}: $space !important;
            }
        }
    }
}

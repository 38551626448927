/* Z-index mapping for the floating elements */
$zindex-input-icon: 5;

$zindex-card-preview-elements: 5;

$zindex-navbar: 10;

$zindex-sidebar: 15;

$zindex-fixed-header: 20;

$zindex-desktop-backdrop: 50;

$zindex-offcanvas: 99;

$zindex-mobile-backdrop: 100;

$zindex-modal: 500;

$zindex-dropdown: 1000;

$zindex-popover-backdrop: 1050;

$zindex-popover: 2000;

$zindex-tooltip: 3000;

$zindex-uploader: 5000;

$zindex-loading-backdrop: 9999;

/* Transitions */
$transition-fast: 0.15s;
$transition-medium: 0.25s;
$transition-slow: 0.35s;
$transition-slowest: 0.5s;

$transition-smooth-fast: $transition-fast ease;
$transition-smooth-medium: $transition-medium ease;
$transition-smooth-slow: $transition-slow ease;
$transition-smooth-slowest: $transition-slowest ease;

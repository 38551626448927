@use "../variables/colors";

@mixin skeleton {
    position: relative;
    background: colors.$atlas-secondary-100;
    color: transparent;
    border-radius: 8px !important;
    margin: 0;
    padding: 0;
    border: none;
    overflow: hidden;
    width: 100%;
    height: auto;

    &::after {
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
            90deg,
            rgba(colors.$white, 0) 0,
            rgba(colors.$white, 0.2) 20%,
            rgba(colors.$white, 0.5) 60%,
            rgba(colors.$white, 0)
        );
        animation: shimmer 1.5s infinite;
        content: "";
    }
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}
